<div style="min-height: 200px">
    <ngx-charts-bar-vertical [yAxisLabel]="'Stunden'"
                             [showYAxisLabel]="true"
                             [xAxis]="true"
                             [yAxis]="true"
                             [showDataLabel]="true"
                             [results]="hoursByDay"
                             style="margin:20px"
    >
    </ngx-charts-bar-vertical>
</div>
