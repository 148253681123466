import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {durationForTrack, formatDuration, Track} from "../../tracks/track";
import {MatPaginator} from "@angular/material/paginator";
import {MatTableDataSource} from "@angular/material/table";
import * as moment from "moment/moment";
import {TrackListComponent} from "../../tracks/track-list/track-list.component";

@Component({
    selector: 'app-time-track-list',
    templateUrl: './time-track-list.component.html',
    styleUrls: ['./time-track-list.component.scss']
})
export class TimeTrackListComponent implements OnInit, OnChanges {
    @Input() tracks: Track[] = [];
    @Input() actions: {
        icon: string,
        action: (track: Track) => void
    }[] = [];

    @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;

    dataSource = new MatTableDataSource<Track>();

    displayedColumns = [
        'time',
        'tags',
        'note',
        'user',
        'duration',
        'actions'
    ];

    totalDuration = '';

    ngOnInit() {
        setTimeout(() => this.dataSource.paginator = this.paginator);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!this.tracks) {
            return;
        }
        this.dataSource.data = [...this.tracks];

        const duration = moment.duration(this.tracks.reduce((a, b) => a + durationForTrack(b), 0));
        this.totalDuration = formatDuration(duration);
    }

    displayTrackRuntime(track: Track): string {
        const diff = durationForTrack(track);
        return formatDuration(moment.duration(diff));
    }
}
