import {Component, OnInit, ViewChild} from '@angular/core';
import {ClockService} from './clock-service/clock.service';
import {Observable} from 'rxjs';
import {MatDrawer} from '@angular/material/sidenav';

import {AngularFireAuth} from '@angular/fire/auth';

import {debounceTime, filter} from 'rxjs/operators';
import {AngularFirestore} from "@angular/fire/firestore";
import firebase from 'firebase/app';
import 'firebase/auth';
import User = firebase.User;
import {MatPaginator} from "@angular/material/paginator";
import {UpdateService} from './updates/update.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    title = 'time-web-app';

    user: User;

    clock: Observable<Date>;

    darkMode = false;

    @ViewChild(MatDrawer) sideNav: MatDrawer;
    @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;

    links = [
        {
            url: '',
            name: 'Track Liste',
            requiresAdmin: false
        },
        {
            url: 'payroll',
            name: 'Vergangene Abrechnung',
            requiresAdmin: false
        },
        {
            url: 'reporting',
            name: 'Reporting',
            requiresAdmin: true
        },
        {
            url: 'payrollAdmin',
            name: 'Abrechnungsadministration',
            requiresAdmin: true
        },
        {
            url: 'tagAdmin',
            name: 'Tagadministration',
            requiresAdmin: true
        },
        {
            url: 'timeSheets',
            name: 'Leistungsnachweise',
            requiresAdmin: true
        }
    ];

    constructor(
        private fireAuth: AngularFireAuth,
        private firestore: AngularFirestore,
        private clockService: ClockService,
        private snackBar: MatSnackBar,
        private updateService: UpdateService
    ) {
    }

    ngOnInit(): void {
        this.darkMode = JSON.parse(localStorage.getItem('dark-mode'));
        this.setTheme(this.darkMode);

        this.updateService.updateAvailable.subscribe(() => {
           const snackbar = this.snackBar.open('Update verfügbar', 'Ausführen');
           snackbar.onAction().subscribe(() => this.updateService.activateUpdate());
        });

        this.fireAuth.authState
            .pipe(
                debounceTime(500)
            )
            .subscribe(async status => {
                if (status === null) {
                    await this.login();
                }
            });

        this.clock = this.clockService.getClock();

        this.fireAuth.user
            .pipe(filter(x => !!x))
            .subscribe(async user => {
            this.user = user;

            const isAdmin = (await this.user.getIdTokenResult()).claims['admin'];

            this.links = this.links.filter(link => link.requiresAdmin === false || isAdmin);
        });
    }

    public async login() {
        const provider = new firebase.auth.GoogleAuthProvider();
        provider.setCustomParameters({prompt: 'select_account'});
        await this.fireAuth.signInWithRedirect(provider);
    }

    public async logout() {
        await this.fireAuth.signOut();
    }

    public async openSideNav() {
        await this.sideNav.open();
    }

    public changeTheme() {
        this.darkMode = !this.darkMode;
        localStorage.setItem('dark-mode', JSON.stringify(this.darkMode));
        this.setTheme(this.darkMode);
    }

    public setTheme(darkMode: boolean) {
        if (darkMode) {
            document.body.classList.add('dark-theme');
        } else {
            document.body.classList.remove('dark-theme');
        }
    }
}
