import {Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {TimeSheet} from '../time-sheet';
import {TimeSheetService} from '../time-sheet-service';

@Component({
    selector: 'app-time-sheet-list',
    templateUrl: './time-sheet-list.component.html',
    styleUrls: ['./time-sheet-list.component.scss']
})
export class TimeSheetListComponent {

    @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;

    public dataSource = new MatTableDataSource<TimeSheet>();

    public displayedColumns = [
        'name',
        'start',
        'end',
        'projectId',
        'actions'
    ];

    constructor(private timeSheetService: TimeSheetService) {
        setTimeout(() => this.dataSource.paginator = this.paginator);

        timeSheetService.timeSheets$.subscribe(sheets => {
            this.dataSource.data = [...sheets].sort((a, b) => (b.start?.toMillis() ?? 0) - (a.start?.toMillis() ?? 0));
        });
    }
}
