import {Component, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {Tag} from '../tag';
import {TagService} from '../tag.service';
import {MatPaginator} from '@angular/material/paginator';

@Component({
    selector: 'app-tag-admin',
    templateUrl: './tag-admin.component.html',
    styleUrls: ['./tag-admin.component.scss']
})
export class TagAdminComponent {

    @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;

    public dataSource = new MatTableDataSource<Tag>();

    public displayedColumns = [
        'name',
        'archived',
        'private',
        'userIds',
        'parentId',
        'reference'
    ];

    constructor(private tagService: TagService) {
        setTimeout(() => this.dataSource.paginator = this.paginator);

        tagService.getTagsObservable().subscribe(tags => {
            this.dataSource.data = [...tags];
        });
    }
}
