<div *ngIf="!user" style="height: 100vh; width: 100vw; display: flex; justify-content: center; align-items: center;">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
</div>
<mat-drawer-container *ngIf="user">
    <mat-drawer mode="over">
        <div class="name-display">
            {{ user?.displayName }}
        </div>
        <br>
        <mat-nav-list>
            <a mat-list-item [routerLink]="link.url" *ngFor="let link of links"> {{ link.name }} </a>
        </mat-nav-list>
        <button mat-flat-button (click)="changeTheme()">
            I dOn'T lIkE thE tHeMe
        </button>
        <br>
        <button mat-flat-button (click)="user ? logout() : login()">
            {{ user ? 'Logout' : 'Login' }}
        </button>
    </mat-drawer>
    <mat-drawer-content>
        <mat-toolbar color="primary">
            <button mat-icon-button (click)="openSideNav()">
                <mat-icon>menu</mat-icon>
            </button>
            BrainTree TIME
            <span class="fill-remaining-space"></span>
            {{ clock | async | date:'HH:mm:ss' }}
        </mat-toolbar>
        <router-outlet></router-outlet>
    </mat-drawer-content>
</mat-drawer-container>
