import {Pipe, PipeTransform} from '@angular/core';
import {TagService} from './tag.service';

@Pipe({
    name: 'tagName'
})
export class TagNamePipe implements PipeTransform {

    constructor(private tagService: TagService) {
    }

    async transform(tagId: string): Promise<string> {
        if (!tagId) {
            return '';
        }

        return await this.tagService.resolveFullTagName(tagId);
    }
}
