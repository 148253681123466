import firebase from 'firebase/app';
import Timestamp = firebase.firestore.Timestamp;

export function parseRawPayroll(raw: PayrollRaw) {
    return {
        id: raw.id,
        start: raw.start.toDate(),
        end: raw.end.toDate(),
        userId: raw.userId,
        tracks: raw.tracks,
        workedDurationInHours: raw.workedDurationInHours,
        contractedHours: raw.contractedHours
    };
}

export interface Payroll {
    id: string;
    start: Date;
    end: Date;
    userId: string;
    workedDurationInHours: number;
    contractedHours: number;
    tracks: string[];
}

export interface PayrollRaw {
    id: string;
    start: Timestamp;
    end: Timestamp;
    userId: string;
    workedDurationInHours: number;
    contractedHours: number;
    tracks: string[];
}
