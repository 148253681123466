<div class="head-container card-margin">
    <mat-card class="current-task-card">
        <mat-card-header style="justify-content: space-between">
            <mat-card-title>
                    Zeiterfassung
            </mat-card-title>
            <button mat-icon-button (click)="startTrackPanelConfigurationActive = !startTrackPanelConfigurationActive">
                <mat-icon *ngIf="!startTrackPanelConfigurationActive">settings</mat-icon>
                <mat-icon *ngIf="startTrackPanelConfigurationActive">close</mat-icon>
            </button>
        </mat-card-header>
        <mat-card-content>
            <!-- ToDo you need to split this up. You would really be annoyed if someone else did this -->
            <ng-container *ngIf="!startTrackPanelConfigurationActive">
                <ng-container *ngIf="currentTrack | async">
                    Ab {{ (currentTrack | async).start | date:'dd.MM.yyyy HH:mm:ss' }}
                    <br/>
                    Aktuelle Dauer {{ displayTrackRuntime(currentTrack | async) }}
                    <br/>
                    <form [formGroup]="currentTrackForm" style="width: 80%">
                        <mat-form-field style="width: 100%">
                            <textarea matInput placeholder="Notiz"
                                      formControlName="note">
                            </textarea>
                        </mat-form-field>
                        <app-tag-selection-input formControlName="tagIds"
                                                 [userIdForNewTags]="(currentTrack | async).userId"></app-tag-selection-input>
                    </form>
                </ng-container>
                <ng-container *ngIf="!(currentTrack | async)">
                    Derzeit wird keine Zeit erfasst.
                </ng-container>
            </ng-container>
            <ng-container *ngIf="startTrackPanelConfigurationActive">
                <form [formGroup]="startTrackPanelConfigurationFormGroup" style="max-width: 95%;">
                    <mat-checkbox formControlName="showStartBeforeEachTag"  style="margin-bottom: 10px;">
                        "Start" in jedem Button anzeigen
                    </mat-checkbox>
                    <app-tag-selection-input formControlName="tagsForStartButton"></app-tag-selection-input>
                </form>
            </ng-container>
        </mat-card-content>
        <mat-card-actions>
            <ng-container *ngIf="!(currentTrack | async) && !startTrackPanelConfigurationActive">
                <button [disabled]="started" mat-button (click)="startTrack()">Start
                </button>
                <button *ngFor="let tag of projectTags | async" mat-button (click)="startTrack(tag.id)">
                    <span *ngIf="showStartForEveryButton">Start </span>{{tag.name}}
                </button>
            </ng-container>

            <button *ngIf="!!(currentTrack | async) && !startTrackPanelConfigurationActive" mat-button (click)="stopTrack()">Stop</button>
        </mat-card-actions>
    </mat-card>
    <mat-card class="worked-hours-card">
        <mat-card-header>
            <mat-card-title>Gearbeitete Zeit</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <mat-list>
                <mat-list-item>
                    Heute: {{workedTimeToday}}
                </mat-list-item>
                <mat-list-item>
                    Diese Woche: {{workedTimeThisWeek}}
                </mat-list-item>
                <mat-list-item>
                    Diesen Monat: {{workedTimeThisMonth}}
                </mat-list-item>
                <mat-list-item *ngIf="workedSinceLastPayroll">
                    Seit letzter Abrechnung: {{workedSinceLastPayroll}}
                </mat-list-item>
            </mat-list>
            <p>Diese Zeiten werden alle ~ 10 Sekunden aktualisiert</p>
        </mat-card-content>
    </mat-card>
</div>
<mat-card class="card-margin" *ngIf="showTrackBarChart">
    <mat-card-content>
        <app-track-bar-chart [tracks]="tracks"></app-track-bar-chart>
    </mat-card-content>
</mat-card>
<mat-card class="card-margin">
    <mat-card-header style="justify-content: space-between">
        <mat-card-title>
            Bisher erfasste Zeiten
        </mat-card-title>
        <button mat-icon-button (click)="setShowTrackBarChart(!showTrackBarChart)">
            <mat-icon>bar_chart</mat-icon>
        </button>
    </mat-card-header>
    <mat-card-content>
        <table mat-table [dataSource]="dataSource" style="width:100%;" multiTemplateDataRows>
            <ng-container matColumnDef="time">
                <th mat-header-cell *matHeaderCellDef>Zeit</th>
                <td mat-cell *matCellDef="let track">
                    {{track.start | date:'dd.MM.yyyy'}} {{track.start | date:'HH:mm:ss'}}
                    - {{track.end | date:'HH:mm:ss'}}
                </td>
                <td mat-footer-cell *matFooterCellDef> Total</td>
            </ng-container>
            <ng-container matColumnDef="note">
                <th mat-header-cell *matHeaderCellDef>Notiz</th>
                <td mat-cell *matCellDef="let track">{{track.note}}</td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="duration">
                <th mat-header-cell *matHeaderCellDef>Dauer</th>
                <td mat-cell *matCellDef="let track"> {{ displayTrackRuntime(track) }}  </td>
                <td mat-footer-cell *matFooterCellDef>{{totalDuration}}</td>
            </ng-container>

            <ng-container matColumnDef="tags">
                <th mat-header-cell *matHeaderCellDef>Tags</th>
                <td mat-cell *matCellDef="let track">
                    <app-tag-chips [tagIds]="track.tagIds"></app-tag-chips>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>Aktionen</th>
                <td mat-cell *matCellDef="let track; let i = dataIndex;">
                    <ng-container *ngIf="!track.processed">
                        <ng-container *ngIf="i === 0 && dataSource.paginator.pageIndex === 0">
                            <button *ngIf="track.end" mat-icon-button
                                    (click)="$event.stopPropagation(); resumeTrack(track);">
                                <mat-icon>play_arrow</mat-icon>
                            </button>
                            <button *ngIf="!track.end" mat-icon-button
                                    (click)="$event.stopPropagation(); stopTrack(track);">
                                <mat-icon>stop</mat-icon>
                            </button>
                        </ng-container>
                        <button mat-icon-button (click)="$event.stopPropagation(); selectTrack(track);">
                            <mat-icon>edit</mat-icon>
                        </button>
                        <button mat-icon-button (click)="$event.stopPropagation(); triggerDeleteTrack(this.track);">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </ng-container>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
            <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let track" [attr.colspan]="displayedColumns.length">
                    <div [@detailExpand]="track == expandedElement ? 'expanded' : 'collapsed'"
                         class="detail-row-container">
                        <div style="margin: 5px">
                            <app-track-form #trackForm [track]="track"></app-track-form>
                            <button mat-button (click)="saveAndCloseForm(trackForm)">
                                Speichern
                            </button>
                            <button mat-button (click)="discardAndCloseForm()">
                                Schließen
                            </button>
                        </div>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns;"></tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-expanded-row"></tr>

            <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>
        </table>
        <mat-paginator [pageSize]="10"
                       [pageSizeOptions]="[5, 10, 25, 100]"
                       showFirstLastButtons>
        </mat-paginator>
    </mat-card-content>
</mat-card>

<div class="fab-container">
    <button mat-fab (click)="openTrackAddDialog()">
        <mat-icon>add</mat-icon>
    </button>
</div>
