import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {TrackFormComponent} from '../track-form/track-form.component';
import {Track} from "../track";

@Component({
    selector: 'app-track-add-dialog',
    templateUrl: './track-add-dialog.component.html',
    styleUrls: ['./track-add-dialog.component.scss']
})
export class TrackAddDialogComponent {

    track: Partial<Track> = {
        note: '',
        start: new Date(),
        tagIds: []
    };

    constructor(private dialogRef: MatDialogRef<TrackAddDialogComponent>) {

    }

    async saveAndClose(trackFormComponent: TrackFormComponent)  {
        const result = await trackFormComponent.save();
        if (result) {
            this.dialogRef.close();
        }
    }

    closeDialog() {
        this.dialogRef.close();
    }
}
