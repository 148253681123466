import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AngularFireAuth} from '@angular/fire/auth';
import {environment} from '../../environments/environment';
import {filter, map, switchMap} from 'rxjs/operators';
import {AngularFirestore} from '@angular/fire/firestore';
import {parseRawPayroll, Payroll} from './payroll';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PayrollService {

    constructor(
        private http: HttpClient,
        private fireAuth: AngularFireAuth,
        private firestore: AngularFirestore
    ) {}

    public getOwnPayrolls(): Observable<Payroll[]> {
        return this.fireAuth.user.pipe(
            filter(user => !!user),
            switchMap(user => {
                return this.firestore.collection(
                    '/payroll',
                    ref => ref
                        .where('userId', '==', user.uid)
                        .orderBy('start', 'desc')
                ).valueChanges({idField: 'id'});
            }),
            map(payroll => payroll.map(parseRawPayroll))
        );
    }

    public getLatestOwnPayroll(): Observable<Payroll> {
        return this.fireAuth.user.pipe(
            filter(user => !!user),
            switchMap(user => {
                return this.firestore.collection(
                    '/payroll',
                    ref => ref
                        .where('userId', '==', user.uid)
                        .orderBy('start', 'desc')
                        .limit(1)
                ).valueChanges({idField: 'id'});
            }),
            filter(x => x.length > 0),
            map(payrolls => payrolls[0]),
            map(parseRawPayroll)
        );
    }

    public async createPayroll(userId: string, start: Date, end: Date): Promise<void> {
        const user = await this.fireAuth.currentUser;
        const token = await user.getIdToken();

        await this.http.patch(`${environment.backendUrl}/EmployeeAccounting/cashup`, {}, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            params: {
                'userId': userId,
                'start': start.toISOString(),
                'end': end.toISOString()
            }
        }).toPromise();
    }

    public async createNextPayroll(userId: string): Promise<void> {
        const user = await this.fireAuth.currentUser;
        const token = await user.getIdToken();

        await this.http.patch(`${environment.backendUrl}/EmployeeAccounting/createNext`, {}, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            params: {
                'userId': userId
            }
        }).toPromise();
    }

    public async deletePayroll(id: string): Promise<void> {
        const user = await this.fireAuth.currentUser;
        const token = await user.getIdToken();

        await this.http.delete(`${environment.backendUrl}/EmployeeAccounting/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).toPromise();
    }
}
