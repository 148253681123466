import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {TimeSheet} from "./time-sheet";
import {AngularFirestore} from "@angular/fire/firestore";
import {map} from "rxjs/operators";
import {Moment} from "moment/moment";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {BaseService} from "../utils/base-service";
import {AngularFireAuth} from "@angular/fire/auth";

@Injectable({
    providedIn: 'root'
})
export class TimeSheetService extends BaseService {
    timeSheets$: Observable<TimeSheet[]> = this.firestore.collection('/timeSheets')
        .valueChanges({idField: 'id'})
        .pipe(map(x => x as unknown as TimeSheet[]));

    constructor(private firestore: AngularFirestore, fireAuth: AngularFireAuth, private http: HttpClient) {
        super(fireAuth);
    }

    public async createTimeSheet(start: Moment, end: Moment, name: string, trackIds: string[]): Promise<string> {
        return await this.http.post<string>(environment.backendUrl + '/timeSheet', {
                start,
                end,
                name,
                trackIds
            },
            {
                observe: "body",
                headers: await this.getDefaultHeaders()
            }).toPromise();
    }

    public patchTimeTrackIds(timeSheetId: string, timeTrackIds: string[], notBilledTimeTrackIds: string[]): Promise<void> {
        const ref = this.firestore.collection<TimeSheet>('/timeSheets').doc(timeSheetId);

        return ref.update({
            timeTrackIds: timeTrackIds,
            notBilledTimeTrackIds: notBilledTimeTrackIds,
        })
    }

    public moveTrackToAnotherTimeSheet(sourceSheetId: string, targetSheetId: string, trackId: string): Promise<void> {
        return;
        const sourceRef = this.firestore.firestore.collection('/timeSheets').doc(sourceSheetId);
        const targetRef = this.firestore.firestore.collection('/timeSheets').doc(targetSheetId);

        return this.firestore.firestore.runTransaction(async transaction => {
            const source = await transaction.get(sourceRef);
            const target = await transaction.get(targetRef);

            const sourceTracks = source.get('timeTrackIds') as string[];
            const targetTracks = target.get('timeTrackIds') as string[];

            const track = sourceTracks.find(id => id === trackId);
            if (!track) {
                return;
            }

            const newSourceTracks = sourceTracks.filter(id => id !== trackId);
            const newTargetTracks = (targetTracks ?? []).concat(trackId);

            transaction.update(sourceRef, {
                timeTrackIds: newSourceTracks
            });
            transaction.update(targetRef, {
                timeTrackIds: newTargetTracks
            });
        });
    }
}
