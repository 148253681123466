import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {ReportingComponent} from './reporting/reporting.component';
import {TrackListComponent} from './tracks/track-list/track-list.component';
import {PayrollComponent} from './payroll/payroll.component';
import {PayrollAdminComponent} from './payroll/payroll-admin/payroll-admin.component';
import {TagAdminComponent} from './tags/tag-admin/tag-admin.component';
import {TimeSheetDetailsViewComponent} from "./time-sheets/time-sheet-details-view/time-sheet-details-view.component";
import {TimeSheetListComponent} from "./time-sheets/time-sheet-list/time-sheet-list.component";
import {TimeSheetCreationComponent} from "./time-sheets/time-sheet-creation/time-sheet-creation.component";

const routes: Routes = [
    {path: 'reporting', component: ReportingComponent},
    {path: 'payroll', component: PayrollComponent},
    {path: 'payrollAdmin', component: PayrollAdminComponent},
    {path: 'tagAdmin', component: TagAdminComponent},
    {path: 'timeSheets', component: TimeSheetListComponent},
    {path: 'timeSheets/new', component: TimeSheetCreationComponent},
    {path: 'timeSheets/:id', component: TimeSheetDetailsViewComponent},
    {path: '', component: TrackListComponent}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
