import {ApplicationRef, Injectable} from '@angular/core';
import {SwUpdate, UpdateAvailableEvent} from '@angular/service-worker';
import {first} from 'rxjs/operators';
import {concat, ReplaySubject, timer} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UpdateService {

    private updateAvailableSubject = new ReplaySubject<UpdateAvailableEvent>(1);
    updateAvailable = this.updateAvailableSubject.asObservable();

    constructor(
        private appRef: ApplicationRef,
        private updates: SwUpdate
    ) {
        const appIsStable$ = appRef.isStable.pipe(first(isStable => isStable === true));
        const everyFiveMinutes = timer(0, 5 * 60 * 1000);
        const everyFiveMinutesOnceAppIsStable$ = concat(appIsStable$, everyFiveMinutes);

        everyFiveMinutesOnceAppIsStable$.subscribe(() => updates.checkForUpdate());

        updates.available.subscribe(event => {
            this.updateAvailableSubject.next(event);
        });
    }

    public activateUpdate() {
        this.updates.activateUpdate().then(() => document.location.reload());
    }
}
