import {Injectable} from "@angular/core";
import {HttpClient, HttpResponse} from "@angular/common/http";
import {AngularFireAuth} from "@angular/fire/auth";
import {environment} from "../../environments/environment";
import {TimeTrackUser} from "./time-track-user";
import {Moment} from "moment";
import {BaseService} from '../utils/base-service';

@Injectable({
    providedIn: 'root'
})
export class ReportingService extends BaseService {

    constructor(
        private http: HttpClient,
        fireAuth: AngularFireAuth
    ) {
        super(fireAuth);
    }

    public async getReport(tags: string[], start: Moment, end: Moment): Promise<TimeTrackUser[]> {
        const params = {
            tags: tags
        };

        if (start) {
            params['start'] = start.toISOString();
        }
        if (end) {
            params['end'] = end.toISOString();
        }

        return this.http.get<TimeTrackUser[]>(environment.backendUrl + '/reporting', {
            headers: await this.getDefaultHeaders(),
            params: params
        }).toPromise();
    }

    public async getCsvReport(tags: string[], start: Moment, end: Moment, aggregate: boolean = false): Promise<[string, Blob]> {
        const params = {
            tags: tags,
            aggregateDays: aggregate
        };

        return this.getGenericCsvReport('csv', params, start, end);
    }

    public async getTimeSheetAsPdf(timeSheetId: string, aggregate: boolean = false): Promise<[string, Blob]> {
        const response = await this.http.get(environment.backendUrl + `/reporting/timesheet-pdf`, {
            headers: await this.getDefaultHeaders(),
            observe: 'response',
            responseType: 'blob',
            params: {
                timeSheetId: timeSheetId,
                aggregate: aggregate
            }
        }).toPromise();

        return this.handleResponse(response);
    }


    public async getTimeSheet(timeSheetId: string, aggregate: boolean = false): Promise<[string, Blob]> {
        const response = await this.http.get(environment.backendUrl + `/reporting/timesheet`, {
            headers: await this.getDefaultHeaders(),
            observe: 'response',
            responseType: 'blob',
            params: {
                timeSheetId: timeSheetId,
                aggregate: aggregate
            }
        }).toPromise();

        return this.handleResponse(response);
    }

    private async getGenericCsvReport(endpoint: string, params: { [p: string]: any }, start: Moment, end: Moment): Promise<[string, Blob]> {
        if (start) {
            params['start'] = start.toISOString();
        }
        if (end) {
            params['end'] = end.toISOString();
        }

        const response = await this.http.get(environment.backendUrl + `/reporting/${endpoint}`, {
            headers: await this.getDefaultHeaders(),
            observe: 'response',
            responseType: 'blob',
            params: params
        }).toPromise();

        return this.handleResponse(response);
    }

    private handleResponse(response: HttpResponse<Blob>): [string, Blob] {
        let fileName: string = null;
        try {
            const contentDisposition = response.headers.get('content-disposition').split(';');
            const fileNameExpression = contentDisposition.filter(x => x.split('=')[0].trim() === 'filename')[0];
            fileName = fileNameExpression.split('=')[1].trim().replace(/(^"|"$)/g, '');
        } catch { /* sucks but fine */
        }

        return [fileName, response.body];
    }
}
