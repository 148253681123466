import {Component, EventEmitter, forwardRef, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import * as moment from 'moment';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

/**
 * Provides a simple material based component for accepting DateTime inputs.
 * The used data format is from 'moment.js'.
 */
@Component({
    selector: 'app-datetime-picker',
    templateUrl: './datetime-picker.component.html',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => DatetimePickerComponent),
        multi: true
    }]
})
export class DatetimePickerComponent implements OnChanges, ControlValueAccessor {
    @Input() placeholder: string;

    @Input() dateTime: moment.Moment;
    @Output() dateTimeChange = new EventEmitter();

    // Binds to the datepicker
    date: moment.Moment;

    // Binds to the time input
    time: string;

    touched = false;

    disabled = false;

    ngOnChanges(changes: SimpleChanges) {
        // If the dateTime is updated by the parent the fields need to be
        // updated.
        if (changes['dateTime']) {
            this.writeValue(changes['dataTime'].currentValue);
        }
    }

    /**
     * Builds a moment from the date and time part. The built moment is
     * emmited.
     */
    public updateDateTime() {
        if (!this.date || !this.time) {
            return;
        }
        const timeSplit = this.time.split(':');
        const newDate = moment([
            this.date.year(),
            this.date.month(),
            this.date.date(),
            Number.parseInt(timeSplit[0], 10),
            Number.parseInt(timeSplit[1], 10)
        ]);
        this.dateTimeChange.emit(newDate);
    }

    /**
     * Sets the given value to both fields
     *
     * @param dateTime the new value
     */
    writeValue(dateTime: moment.Moment): void {
        if (dateTime === null || !dateTime.isValid()) {
            this.date = null;
            this.time = null;
        } else {
            this.date = dateTime;
            this.time = `${dateTime.format('HH')}:${dateTime.format('mm')}`;
        }
    }

    registerOnChange(fn: any): void {
        this.dateTimeChange.subscribe((value: moment.Moment) => fn(value));
    }
    registerOnTouched(fn: any): void {
        this.touched = true;
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}
