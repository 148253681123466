import {Component, ElementRef, Input, ViewChild} from '@angular/core';
import {MatChipInputEvent} from "@angular/material/chips";
import {Tag} from "../../tags/tag";
import {TagService} from "../../tags/tag.service";
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {
    ControlValueAccessor,
    FormBuilder,
    FormGroup,
    NG_VALUE_ACCESSOR
} from "@angular/forms";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {map, startWith, switchMap} from "rxjs/operators";
import {BehaviorSubject, Observable} from "rxjs";

@Component({
    selector: 'app-tag-selection-input',
    templateUrl: './tag-selection-input.component.html',
    styleUrls: ['./tag-selection-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: TagSelectionInputComponent
        }
    ]
})
export class TagSelectionInputComponent implements ControlValueAccessor {

    /**
     * Is only needed if new tags can be created.
     */
    @Input() userIdForNewTags: string;
    @Input() canCreateNew: boolean = true;

    onTouched: () => void;
    onChange: (tagIds: string[]) => void;

    touched = false;

    inputSeparatorKeyCodes = [ENTER, COMMA];

    @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;

    tagIdsSubject = new BehaviorSubject([]);

    tags$: Observable<Tag[]> = this.tagIdsSubject
        .pipe(
            switchMap(ids => {
                return this.tagService.getTagsObservable()
                    .pipe(map(tags => tags.filter(tag => ids.includes(tag.id))))
            })
        );

    innerForm: FormGroup = this.formBuilder.group({
        tagSearch: ['']
    });

    private tagSearchControl = () => this.innerForm.get('tagSearch');

    public filteredTags$ = this.tagSearchControl()
        .valueChanges
        .pipe(
            startWith(null as (string | undefined)),
            this.tagService.searchTagsPipe(),
            map(tags => tags.filter(tag => !this.tagIdsSubject.value.includes(tag.id))),
            map(tags => tags.sort((x, y) => x.name.localeCompare(y.name)))
        );

    constructor(private readonly tagService: TagService, private readonly formBuilder: FormBuilder) {
    }

    public async writeValue(tagIds: string[]) {
        this.tagIdsSubject.next(tagIds);
    }

    public registerOnChange(fn: any) {
        this.onChange = fn;
    }

    public registerOnTouched(fn: any) {
        this.onTouched = fn;
    }

    public selectedTag(event: MatAutocompleteSelectedEvent): void {
        this.addTag(event.option.value);

        this.tagSearchControl().setValue('');
        this.tagInput.nativeElement.value = '';
    }

    public async add(event: MatChipInputEvent): Promise<void> {
        const value = event.value;

        if ((value || '').trim()) {
            const tags = await this.tagService.getTags();
            let tag = tags.find(x => x.name.localeCompare(
                    value,
                    'de',
                    {sensitivity: 'base'}
                ) === 0
            );

            if (tag) {
                this.addTag(tag);
            } else if (this.canCreateNew) {
                tag = await this.tagService.createTag({
                    name: value,
                    private: true,
                    archived: false,
                    onlySystem: false,
                    userIds: [this.userIdForNewTags],
                    reference: '',
                    parentId: null
                });
                this.addTag(tag);
            }
        }

        // Reset the input value
        this.tagSearchControl().setValue('');
        this.tagInput.nativeElement.value = '';
    }

    public addTag(tag: Tag) {
        if (!this.tagIdsSubject.value.includes(tag.id)) {
            this.tagIdsSubject.next([...this.tagIdsSubject.value, tag.id])
            this.onChange(this.tagIdsSubject.value);
            this.markAsTouched();
        }
    }

    public remove(tag: Tag) {
        this.tagIdsSubject.next(this.tagIdsSubject.value.filter(x => x !== tag.id));
        this.onChange(this.tagIdsSubject.value);
        this.markAsTouched();
    }

    private markAsTouched() {
        if (!this.touched) {
            this.onTouched();
            this.touched = true;
        }
    }
}
