import {Component} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {TimeSheetService} from "../time-sheet-service";
import {aggregateTrackRuntime, durationForTrack, formatDuration, Track} from '../../tracks/track';
import {Router} from "@angular/router";
import {TrackService} from "../../tracks/track.service";
import * as moment from "moment";

const TimeSheetCreationFormStorageKey = "TimeSheetCreationForm";

@Component({
    selector: 'app-time-sheet-creation',
    templateUrl: './time-sheet-creation.component.html',
    styleUrls: ['./time-sheet-creation.component.scss']
})
export class TimeSheetCreationComponent {
    public tracks: Track[];
    public workedDuration: string;

    public formGroup = new FormGroup({
        start: new FormControl(null),
        end: new FormControl(null),
        tagIds: new FormControl([]),
        name: new FormControl('', {validators: [Validators.required]})
    });

    constructor(
        private readonly timeSheetService: TimeSheetService,
        private readonly timeTrackService: TrackService,
        private readonly router: Router) {

        const previousValues = sessionStorage.getItem(TimeSheetCreationFormStorageKey);
        if (previousValues !== null) {
            this.formGroup.patchValue(JSON.parse(previousValues));
        }

        this.formGroup.valueChanges.subscribe(
            x => sessionStorage.setItem(TimeSheetCreationFormStorageKey, JSON.stringify(x))
        );
    }

    async search() {
        const data = this.formGroup.value;
        this.tracks = await this.timeTrackService.searchTimeTracks(moment(data.start), moment(data.end).clone().add(1, 'day'), data.tagIds);
        this.workedDuration = formatDuration(aggregateTrackRuntime(this.tracks));
    }

    async createTimeSheet() {
        const data = this.formGroup.value;
        const timeSheetId = await this.timeSheetService.createTimeSheet(data.start, data.end, data.name, this.tracks.map(x => x.id));

        await this.router.navigate(['/timeSheets', timeSheetId]);
    }
}
