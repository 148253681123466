import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {Track} from '../track';
import * as moment from 'moment';
import {TrackService} from '../track.service';

@Component({
    selector: 'app-track-form',
    templateUrl: './track-form.component.html',
    styleUrls: ['./track-form.component.scss']
})
export class TrackFormComponent implements OnChanges {
    @Input()
    track: Track;

    formGroup: FormGroup;

    constructor(
        private trackService: TrackService
    ) {
        this.formGroup = new FormGroup({
            id: new FormControl(),
            userId: new FormControl(),
            start: new FormControl(),
            end: new FormControl(),
            note: new FormControl(),
            tagIds: new FormControl()
        });
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        this.formGroup.patchValue({
            id: this.track.id,
            userId: this.track.userId,
            start: moment(this.track.start),
            end: this.track.end ? moment(this.track.end) : null,
            note: this.track.note,
            tagIds: this.track.tagIds
        });

        this.formGroup.get('start').valueChanges.subscribe((start) => {
            const currentEnd: moment.Moment= this.formGroup.value.end;

            if (start > currentEnd) {
                const diff = Math.ceil(-currentEnd.diff(start, 'day', true));
                this.formGroup.get('end').setValue(moment(currentEnd).add(diff, 'days'));
            }
        })
    }

    public async save(): Promise<boolean> {
        const data = this.formGroup.value;

        // ToDo
        // const tags = await Promise.all(this.tags.map(tag => tag.id ? of(tag).toPromise() : this.tagService.createTag(tag)));

        const track = {
            id: data.id,
            userId: data.userId,
            start: data.start.toDate(),
            end: data.end?.toDate() ?? null,
            note: data.note,
            lastModified: this.track.lastModified ?? new Date(),
            processed: false,
            deleted: false,
            tagIds: data.tagIds,
        };

        if (track.id) {
            return this.trackService.updateTrack(track.id, track);
        } else {
            return this.trackService.createTrack(track);
        }
    }
}
