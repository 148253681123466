import {Component, OnDestroy} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {AngularFireAuth} from '@angular/fire/auth';
import {Payroll} from './payroll';
import {combineLatest, forkJoin, Subscription} from 'rxjs';
import {filter, map, switchMap} from 'rxjs/operators';
import {Track, TrackRaw} from '../tracks/track';
import {PayrollService} from './payroll.service';

@Component({
    selector: 'app-payroll',
    templateUrl: './payroll.component.html',
    styleUrls: ['./payroll.component.scss']
})
export class PayrollComponent implements OnDestroy {
    sub: Subscription = new Subscription();
    payroll: Payroll[];
    overtimeInHours: number = 0;
    overtimeMessage: string = '';

    tracksForPayroll: { [key: string]: Track[] } = {};

    constructor(
        private fireAuth: AngularFireAuth,
        private firestore: AngularFirestore,
        private payrollService: PayrollService
    ) {
        payrollService.getOwnPayrolls()
            .subscribe(data => {
                this.overtimeInHours = data.reduce((sum, item) =>  sum + (item.contractedHours ? item.workedDurationInHours - item.contractedHours : 0), 0);

                if (this.overtimeInHours === 0) {
                    this.overtimeMessage = 'Exactly right.';
                } else if (this.overtimeInHours > 0) {
                    this.overtimeMessage = 'Woah slow down.';
                } else if (this.overtimeInHours < 0) {
                    this.overtimeMessage = 'Work more!';
                }

                this.payroll = data;
            });
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }

    onExpand(event: boolean, payroll: Payroll) {
        if (!event || this.tracksForPayroll[payroll.id]) {
            return;
        }

        this.tracksForPayroll[payroll.id] = [];

        this.sub.add(combineLatest([
            this.fireAuth.user
        ]).pipe(
            filter(user => !!user),
            switchMap(() => {
                return forkJoin(
                    ...payroll.tracks.map(trackId => {
                      return this.firestore.doc('/tracks/' + trackId).get();
                    })
                );
            }),
            map(docs => {
                return docs.map(doc => {
                    const data = doc.data() as TrackRaw;
                    data.id = doc.id;
                    return data;
                });
            }),
            map(tracks => tracks.map((track: TrackRaw) => ({
                    id: track.id,
                    note: track.note,
                    start: track.start?.toDate(),
                    end: track.end?.toDate(),
                    lastModified: track.lastModified?.toDate(),
                    userId: track.userId,
                    deleted: track.deleted ?? false,
                    tagIds: track.tagIds ?? []
                }))
            )
        ).subscribe((data: Track[]) => this.tracksForPayroll[payroll.id] = data));
    }
}
