import firebase from 'firebase/app';
import Timestamp = firebase.firestore.Timestamp;
import * as moment from "moment/moment";

export function parseRawTrack(raw: TrackRaw) {
    return {
        id: raw.id,
        note: raw.note,
        start: raw.start?.toDate(),
        end: raw.end?.toDate(),
        lastModified: raw.lastModified?.toDate(),
        userId: raw.userId,
        processed: raw.processed,
        deleted: raw.deleted ?? false,
        tagIds: raw.tagIds ?? []
    }
}

export function aggregateTrackRuntime(tracks: Track[]): moment.Duration {
    return moment.duration(tracks.reduce((acc, cur) => acc + durationForTrack(cur), 0));
}

export function durationForTrack(track: Track): number {
    let end: moment.Moment;

    if (track.end) {
        end = moment(track.end);
    } else {
        end = moment();
    }

    return end.diff(moment(track.start));
}

export function formatDuration(duration: moment.Duration): string {
    const days = Math.floor(duration.asDays());
    const hours = (days * 24 + duration.hours()).toLocaleString('de', {minimumIntegerDigits: 2});
    const minutes = duration.minutes().toLocaleString('de', {minimumIntegerDigits: 2});
    const seconds = duration.seconds().toLocaleString('de', {minimumIntegerDigits: 2});

    return `${hours}:${minutes}:${seconds}`;
}

export interface Track {
    id: string;

    start: Date;
    end: Date;

    note: string;
    userId: string;

    lastModified: Date;

    processed: boolean;
    deleted: boolean;

    tagIds: string[];
}

export interface TrackRaw {
    id: string;

    start: Timestamp;
    end: Timestamp;

    note: string;
    userId: string;

    lastModified: Timestamp;

    processed: boolean;
    deleted: boolean;

    tagIds: string[];
}
