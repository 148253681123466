<div style="margin: 50px;">
    <mat-card>
        <mat-card-header>
            <mat-card-title>Neue Abrechnungen</mat-card-title>
        </mat-card-header>
        <mat-card-content [formGroup]="formGroup">
            <mat-form-field>
                <mat-label>Nutzer</mat-label>
                <mat-select formControlName="user">
                    <mat-option>-</mat-option>
                    <mat-option *ngFor="let userId of userIds" [value]="userId">
                        {{userId | userName | async}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <br>
            <app-datetime-picker placeholder="Von" formControlName="start"></app-datetime-picker><br>
            <app-datetime-picker placeholder="Bis" formControlName="end"></app-datetime-picker>
        </mat-card-content>
        <mat-card-actions>
            <button mat-flat-button (click)="createPayroll()">Anlegen</button>
            <button mat-flat-button (click)="createNextPayroll()">Nächste Anlegen</button>
        </mat-card-actions>
    </mat-card>

    <ng-container *ngFor="let payrollsPerUser of payrollsPerUserDict | keyvalue">
        <h1>{{payrollsPerUser.key | userName | async}}</h1>
        <mat-accordion multi>
            <mat-expansion-panel *ngFor="let singlePayroll of payrollsPerUser.value"
                                 (expandedChange)="onExpand($event, singlePayroll)">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{singlePayroll.start | date:'dd.MM.yyyy HH:mm'}}
                        - {{singlePayroll.end | date:'dd.MM.yyyy HH:mm'}}
                    </mat-panel-title>
                    <mat-panel-description>
                        {{singlePayroll.workedDurationInHours | number:'1.0-2'}}<ng-container *ngIf="singlePayroll.contractedHours"> / {{singlePayroll.contractedHours | number:'1.0-2'}}</ng-container>
                    </mat-panel-description>
                </mat-expansion-panel-header>

                <mat-progress-spinner mode="indeterminate"
                                      *ngIf="!tracksForPayroll[singlePayroll.id]?.length"></mat-progress-spinner>

                <app-time-track-list *ngIf="tracksForPayroll[singlePayroll.id]?.length" [tracks]="tracksForPayroll[singlePayroll.id]"></app-time-track-list>

                <mat-action-row>
                    <button mat-button color="warn" (click)="deletePayroll(singlePayroll.id)">Abrechnung löschen</button>
                </mat-action-row>
            </mat-expansion-panel>
        </mat-accordion>
    </ng-container>
</div>
