import {Component, OnDestroy} from '@angular/core';
import {combineLatest, forkJoin, Subscription} from 'rxjs';
import {AngularFireAuth} from '@angular/fire/auth';
import {AngularFirestore} from '@angular/fire/firestore';
import {filter, map, switchMap} from 'rxjs/operators';
import {FormBuilder} from '@angular/forms';
import {UserService} from '../../users/user.service';
import {Payroll, PayrollRaw} from '../payroll';
import {Track, TrackRaw} from '../../tracks/track';
import {PayrollService} from '../payroll.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
    selector: 'app-payroll-admin',
    templateUrl: './payroll-admin.component.html',
    styleUrls: ['./payroll-admin.component.scss']
})
export class PayrollAdminComponent implements OnDestroy {

    formGroup = this.formBuilder.group({
        'user': this.formBuilder.control(''),
        'start': this.formBuilder.control(null),
        'end': this.formBuilder.control(null),
    })

    sub: Subscription = new Subscription();
    userIds: string[];
    payroll: Payroll[];

    payrollsPerUserDict: { [key: string]: Payroll[] } = {};

    tracksForPayroll: { [key: string]: Track[] } = {};

    constructor(
        private fireAuth: AngularFireAuth,
        private firestore: AngularFirestore,
        private userService: UserService,
        private payrollService: PayrollService,
        private snackBar: MatSnackBar,
        private formBuilder: FormBuilder
    ) {
        this.sub.add(this.firestore.collection(
            '/payroll',
            ref => ref
                .orderBy('start', 'desc')
        ).valueChanges({idField: 'id'}).pipe(
            map(payroll => payroll.map((p: PayrollRaw) => ({
                    id: p.id,
                    start: p.start.toDate(),
                    end: p.end.toDate(),
                    userId: p.userId,
                    tracks: p.tracks,
                    workedDurationInHours: p.workedDurationInHours,
                    contractedHours: p.contractedHours
                })
            )),
            map(payroll => {
                this.payrollsPerUserDict = {};

                payroll.forEach(p => {
                    if (!this.payrollsPerUserDict[p.userId]) {
                        this.payrollsPerUserDict[p.userId] = [];
                    }

                    this.payrollsPerUserDict[p.userId].push(p);
                });

                this.userIds = Object.keys(this.payrollsPerUserDict);

                return this.payrollsPerUserDict;
            })
        ).subscribe());
    }

    onExpand(event: boolean, payroll: Payroll) {
        if (!event || this.tracksForPayroll[payroll.id]) {
            return;
        }

        this.tracksForPayroll[payroll.id] = [];

        this.sub.add(combineLatest([
            this.fireAuth.user
        ]).pipe(
            filter(user => !!user),
            switchMap(() => {
                return forkJoin(
                    ...payroll.tracks.map(trackId => {
                        return this.firestore.doc('/tracks/' + trackId).get();
                    })
                );
            }),
            map(docs => {
                return docs.map(doc => {
                    const data = doc.data() as TrackRaw;
                    data.id = doc.id;
                    return data;
                });
            }),
            map(tracks => tracks.map((track: TrackRaw) => ({
                    id: track.id,
                    note: track.note,
                    start: track.start?.toDate(),
                    end: track.end?.toDate(),
                    lastModified: track.lastModified?.toDate(),
                    userId: track.userId,
                    deleted: track.deleted ?? false,
                    tagIds: track.tagIds ?? []
                }))
            )
        ).subscribe((data: Track[]) => this.tracksForPayroll[payroll.id] = data));
    }

    async createPayroll(): Promise<void> {
        const converted = {
            ...this.formGroup.value,
            start: this.formGroup.value.start.toDate(),
            end: this.formGroup.value.end.toDate()
        };

        try {
            await this.payrollService.createPayroll(converted.user, converted.start, converted.end);
        } catch (e) {
            console.log(e.error);
            this.snackBar.open(e.error, 'Dismiss', {horizontalPosition: 'end', verticalPosition: 'top'});
        }

    }

    async createNextPayroll(): Promise<void> {
        try {
            await this.payrollService.createNextPayroll(this.formGroup.value.user);
        } catch (e) {
            console.log(e.error);
            this.snackBar.open(e.error, 'Dismiss', {horizontalPosition: 'end', verticalPosition: 'top'});
        }
    }

    async deletePayroll(id: string): Promise<void> {
        try {
            await this.payrollService.deletePayroll(id);
        } catch (e) {
            console.log(e.error);
            this.snackBar.open(e.error, 'Dismiss', {horizontalPosition: 'end', verticalPosition: 'top'});
        }
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }

}
