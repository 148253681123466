<mat-card style="margin: 5px;">
    <mat-card-header>
        <mat-card-title>{{(timeSheet$ | async).name}}
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <h3>Abgerechente Zeiten</h3>
        <app-time-track-list [tracks]="tracks$ | async"  [actions]="actions"></app-time-track-list>
        <h3>Nicht abgerechente Zeiten</h3>
        <app-time-track-list [tracks]="notBilledTracks$ | async"  [actions]="notBilledActions"></app-time-track-list>
    </mat-card-content>
    <mat-card-actions>
        <button mat-button (click)="downloadCsv(false)">Get all rows</button>
        <button mat-button (click)="downloadCsv(true)">Get aggregate</button>
        <button mat-button (click)="downLoadPdf(true)">Get PDF</button>
    </mat-card-actions>
</mat-card>
<pre>
    {{timeSheet$ | async | json}}
</pre>
