<mat-card style="margin: 50px;">
    <mat-card-header>
        <mat-card-title>Derzeitige Tags</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <table mat-table [dataSource]="dataSource" style="width:100%;">
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>Name</th>
                <td mat-cell *matCellDef="let tag">{{tag.name}}</td>
            </ng-container>
            <ng-container matColumnDef="archived">
                <th mat-header-cell *matHeaderCellDef>Archived</th>
                <td mat-cell *matCellDef="let tag">{{tag.archived}}</td>
            </ng-container>

            <ng-container matColumnDef="private">
                <th mat-header-cell *matHeaderCellDef>Private</th>
                <td mat-cell *matCellDef="let tag">{{tag.archived}}</td>
            </ng-container>

            <ng-container matColumnDef="userIds">
                <th mat-header-cell *matHeaderCellDef>User Ids</th>
                <td mat-cell *matCellDef="let tag">{{tag.userIds?.join(', ')}}</td>
            </ng-container>

            <ng-container matColumnDef="parentId">
                <th mat-header-cell *matHeaderCellDef>ParentTag</th>
                <td mat-cell *matCellDef="let tag">{{tag.parentId | tagName | async}}</td>
            </ng-container>

            <ng-container matColumnDef="reference">
                <th mat-header-cell *matHeaderCellDef>Referenz</th>
                <td mat-cell *matCellDef="let tag">{{tag.reference}}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator [pageSize]="10"
                       [pageSizeOptions]="[5, 10, 25, 100]"
                       showFirstLastButtons>
        </mat-paginator>
    </mat-card-content>
</mat-card>
