import {Injectable} from '@angular/core';
import {combineLatest, Observable, OperatorFunction, pipe, ReplaySubject} from 'rxjs';
import {filter, map, switchMap, take, withLatestFrom} from 'rxjs/operators';
import {AngularFirestore} from '@angular/fire/firestore';
import {AngularFireAuth} from '@angular/fire/auth';
import {Tag} from './tag';

@Injectable({
    providedIn: 'root'
})
export class TagService {
    private tagSubject = new ReplaySubject<Tag[]>(1);

    constructor(
        private fireAuth: AngularFireAuth,
        private firestore: AngularFirestore
    ) {
        this.fireAuth.user.pipe(
            filter(user => !!user),
            switchMap((user) =>
                combineLatest([
                    this.firestore.collection('/tags', ref =>
                        ref
                            .where('private', '==', false)
                    ).valueChanges({idField: 'id'}),
                    this.firestore.collection('/tags', ref =>
                        ref
                            .where('private', '==', true)
                            .where('userIds', 'array-contains', user.uid)
                    ).valueChanges({idField: 'id'})
                ])
            ),
            map(([publicTags, privateTags]) => [...publicTags, ...privateTags] as Tag[])
        ).subscribe(tags => {
            this.tagSubject.next(tags);
        });
    }

    public getProjectTagObservable(): Observable<Tag[]> {
        return this.tagSubject
            .pipe(map(tags => tags.filter(tag => !tag.archived && tag.reference && tag.reference.startsWith("p:"))));
    }

    public getTags(): Promise<Tag[]> {
        return this.tagSubject.pipe(take(1)).toPromise();
    }

    public getTagsObservable(): Observable<Tag[]> {
        return this.tagSubject.asObservable();
    }

    public async createTag(tag: Tag): Promise<Tag> {
        const newTag = await this.firestore.collection('/tags').add(tag);
        return {id: newTag.id, ...tag};
    }

    public async updateTag(tag: Partial<Tag>): Promise<void> {
        await this.firestore.collection('/tags').doc(tag.id).update(tag);
    }

    public searchTagsPipe(): OperatorFunction<string | undefined, Tag[]> {
        return pipe(
            withLatestFrom(this.tagSubject.pipe(map(tags => tags
                .filter(x => !x.archived && !x.onlySystem)))),
            map(([value, tags]) => value
                ? tags.filter(tag => RegExp(value, 'i').test(this.resolveFullTagNameWithTags(tag.id, tags)))
                : tags)
        );
    }

    public async resolveFullTagName(tagId): Promise<string> {
        const tags = await this.getTags();

        return this.resolveFullTagNameWithTags(tagId, tags);
    }

    public resolveFullTagNameWithTags(tagId: string, tags: Tag[]): string {
        return this.resolveFullTagNameRecursive(tagId, tags).join('/');
    }

    private resolveFullTagNameRecursive(tagId: string, tags: Tag[]): string[] {
        const tag = tags.find(t => t.id === tagId);

        if (tag == null) {
            return [];
        } else if (tag.parentId) {
            return [...this.resolveFullTagNameRecursive(tag.parentId, tags), tag.name];
        }

        return [tag.name];
    }
}
