<mat-card style="margin: 50px;">
    <mat-card-content>
        <form [formGroup]="formGroup" style="width: 60%">
            <mat-form-field>
                <mat-label>Zeitspanne</mat-label>
                <mat-date-range-input [rangePicker]="picker">
                    <input matStartDate placeholder="Von" formControlName="start">
                    <input matEndDate placeholder="Bis" formControlName="end">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
                <mat-hint>Beide Tage sind eingeschlossen</mat-hint>
            </mat-form-field>
            <br/>
            <!-- maybe change this to select a project? -->
            <app-tag-selection-input formControlName="tagIds" [canCreateNew]="false"></app-tag-selection-input>

            <mat-form-field style="width: 100%">
                <mat-label>Name des Leistungsnachweises</mat-label>
                <input matInput placeholder="Name" formControlName="name"/>
            </mat-form-field>
        </form>

        <pre>
            {{ formGroup.value | json}}
        </pre>
    </mat-card-content>
    <mat-card-actions>
        <button mat-button (click)="search()">Passende Zeiten laden</button>
    </mat-card-actions>
</mat-card>

<ng-container *ngIf="tracks">
    <mat-card style="margin: 50px;">
        <mat-card-content>
            WorkedDuration: {{workedDuration}}
        </mat-card-content>
        <mat-card-actions>
            <button mat-button (click)="createTimeSheet()">Leistungsnachweis erzeugen</button>
        </mat-card-actions>
    </mat-card>

    <mat-card style="margin: 50px;">
        <mat-card-content>
            <app-track-bar-chart [tracks]="tracks"></app-track-bar-chart>
        </mat-card-content>
    </mat-card>

    <mat-card style="margin: 50px;">
        <mat-card-content>
            <app-time-track-list [tracks]="tracks"></app-time-track-list>
        </mat-card-content>
        <mat-card-actions>
        </mat-card-actions>
    </mat-card>
</ng-container>
