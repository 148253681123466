<mat-card style="margin: 50px;">
    <mat-card-content>
        <ng-container [formGroup]="searchFormGroup">
        <mat-form-field>
            <mat-label>Zeitspanne</mat-label>
            <mat-date-range-input [rangePicker]="picker">
                <input matStartDate placeholder="Von" formControlName="searchStart">
                <input matEndDate placeholder="Bis" formControlName="searchEnd">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
            <mat-hint>Beide Tage sind eingeschlossen</mat-hint>
        </mat-form-field>

        <div style="max-width: 400px">
            <app-tag-selection-input [canCreateNew]="false" formControlName="tags">
            </app-tag-selection-input>
        </div>
        </ng-container>

    </mat-card-content>
    <mat-card-actions>
        <button mat-button (click)="requestReport()">Report laden</button>
        <button mat-button (click)="requestCsvReport()">Report als CSV laden</button>
        <button mat-button (click)="requestTimeSheetReport()">Timesheet laden</button>
    </mat-card-actions>
</mat-card>

<mat-card *ngFor="let user of response" style="margin: 50px;">
    <div style="height: 300px; font-family: RobotoDraft,Roboto,sans-serif">
        <ngx-charts-advanced-pie-chart
            [label]="user.user.name"
            [animations]="false"
            [valueFormatting]="formatValue"
            [results]="chartData[user.name]">
        </ngx-charts-advanced-pie-chart>
    </div>
</mat-card>
