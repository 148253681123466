import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {Track} from "../track";

@Component({
    selector: 'app-track-bar-chart',
    templateUrl: './track-bar-chart.component.html',
    styleUrls: ['./track-bar-chart.component.scss']
})
export class TrackBarChartComponent implements OnChanges {

    @Input()
    public tracks: Track[];
    public hoursByDay: { name: string, value: number }[];

    constructor() {
    }

    ngOnChanges() {
        const hoursByDayMap = this.tracks.reduce((hoursByDayMap, current) => {

            if (current.start && current.end) {
                const day = current.start.toDateString();
                const oldValue = hoursByDayMap[day] ?? 0;
                hoursByDayMap[day] = oldValue + (current.end.getTime() - current.start.getTime()) / 1000 / 60 / 60;
            }
            return hoursByDayMap;
        }, {});

        this.hoursByDay = Object.keys(hoursByDayMap).map((key) => ({
            name: key, value: hoursByDayMap[key]
        }));
    }

}
