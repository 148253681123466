<mat-card style="margin: 50px;">
    <mat-card-header>
        <mat-card-title>Leistungsnachweise</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <table mat-table [dataSource]="dataSource" style="width:100%;">
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>Name</th>
                <td mat-cell *matCellDef="let timeSheet">{{timeSheet.name}}</td>
            </ng-container>
            <ng-container matColumnDef="start">
                <th mat-header-cell *matHeaderCellDef>Start</th>
                <td mat-cell *matCellDef="let timeSheet">{{timeSheet.start?.toDate() | date:'dd.MM.yyyy'}}</td>
            </ng-container>

            <ng-container matColumnDef="end">
                <th mat-header-cell *matHeaderCellDef>Ende</th>
                <td mat-cell *matCellDef="let timeSheet">{{timeSheet.end?.toDate() | date:'dd.MM.yyyy'}}</td>
            </ng-container>

            <ng-container matColumnDef="projectId">
                <th mat-header-cell *matHeaderCellDef>Projekt</th>
                <td mat-cell *matCellDef="let timeSheet">{{timeSheet.projectId}}</td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>Aktionen</th>
                <td mat-cell *matCellDef="let timeSheet; let i = dataIndex;">
                    <button mat-icon-button [routerLink]="timeSheet.id">
                        <mat-icon>info</mat-icon>
                    </button>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator [pageSize]="10"
                       [pageSizeOptions]="[5, 10, 25, 100]"
                       showFirstLastButtons>
        </mat-paginator>
    </mat-card-content>
</mat-card>

<div class="fab-container">
    <button mat-fab [routerLink]="['new']">
        <mat-icon>add</mat-icon>
    </button>
</div>
