import {AngularFireAuth} from '@angular/fire/auth';

export class BaseService {
    constructor(protected fireAuth: AngularFireAuth) {
    }

    protected async getDefaultHeaders(): Promise<{ [key: string]: string }> {
        const user = await this.fireAuth.currentUser;
        const token = await user.getIdToken();

        return {
            'Authorization': `Bearer ${token}`
        }
    }
}
