export const environment = {
    production: true,
    useProdFirebase: true,
    firebase: {
        apiKey: 'AIzaSyDsC8n5Qb8VoqUUq_fd3TGinSxZY7EPSls',
        authDomain: 'app.time.braintree-it.de',
        databaseURL: 'https://internal-applications-249612.firebaseio.com',
        projectId: 'internal-applications-249612',
        storageBucket: 'internal-applications-249612.appspot.com',
        messagingSenderId: '279441368914',
        appId: '1:279441368914:web:3d659f2c2c1ff7a61a3c8b'
    },
    backendUrl: 'https://time-backend-hnjf4ipuhq-ey.a.run.app'
};
