<mat-form-field style="width: 100%" [formGroup]="innerForm">
    <mat-label>Tags</mat-label>
    <mat-chip-list #tagList aria-label="Tag selection">
        <mat-chip *ngFor="let tag of tags$ | async" [selectable]="true"
                  [removable]="true" (removed)="remove(tag)">
            {{tag.id | tagName | async}}
            <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
        <input placeholder="Tag hinzufügen"
               formControlName="tagSearch"
               #tagInput
               [matAutocomplete]="tagAuto"
               [matChipInputFor]="tagList"
               [matChipInputSeparatorKeyCodes]="inputSeparatorKeyCodes"
               [matChipInputAddOnBlur]="false"
               (matChipInputTokenEnd)="add($event)">
    </mat-chip-list>
    <mat-autocomplete #tagAuto="matAutocomplete" (optionSelected)="selectedTag($event)">
        <mat-option *ngFor="let tag of filteredTags$ | async" [value]="tag">
            {{tag.id | tagName | async}}
            <ng-container *ngIf="tag.reference?.startsWith('p:')"> | Projekt</ng-container>
        </mat-option>
    </mat-autocomplete>
</mat-form-field>
