import {Component} from '@angular/core';
import {ReportingService} from "./reporting.service";
import {TimeTrackUser} from "./time-track-user";
import {FormControl, FormGroup} from '@angular/forms';
import {downloadFile} from '../utils/file-download';

@Component({
    selector: 'app-reporting',
    templateUrl: './reporting.component.html',
    styleUrls: ['./reporting.component.scss']
})
export class ReportingComponent {
    searchFormGroup = new FormGroup({
        searchStart: new FormControl(),
        searchEnd: new FormControl(),
        tags: new FormControl([])
    })
    response: TimeTrackUser[];
    chartData: { [key: string]: {name: string, value: number}[] } = {};

    constructor(
        private reportingService: ReportingService
    ) {}

    public async requestReport() {
        const data = this.searchFormGroup.value;
        this.response = await this.reportingService.getReport(
            data.tags,
            data.searchStart,
            data.searchEnd.clone().add(1, 'day')
        );

        for (const user of this.response) {
            this.chartData[user.name] = user.projects.map(project => ({
                name: project.name,
                value: project.workedDurationInHours
            }))
        }
    }

    public async requestCsvReport() {
        const data = this.searchFormGroup.value;
        const [fileName, blob] = await this.reportingService.getCsvReport(
            data.tags,
            data.searchStart,
            data.searchEnd.clone().add(1, 'day')
        );

        downloadFile(fileName, blob);
    }

    public async requestTimeSheetReport() {
        if (this.searchFormGroup.value.tags.length !== 1) {
            return;
        }
    }

    public formatValue(value: number): any {
        return value.toFixed(2).toString() + ' h';
    }
}
