<div style="margin: 50px;">
    <h1>Overtime: {{this.overtimeInHours}}</h1>
    <h2>{{this.overtimeMessage}}</h2>
    <mat-accordion multi *ngIf="payroll?.length; else emptyMessage">
        <mat-expansion-panel *ngFor="let singlePayroll of payroll" (expandedChange)="onExpand($event, singlePayroll)">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{singlePayroll.start | date:'dd.MM.yyyy HH:mm'}} - {{singlePayroll.end | date:'dd.MM.yyyy HH:mm'}}
                </mat-panel-title>
                <mat-panel-description>
                    {{singlePayroll.workedDurationInHours | number:'1.0-2'}}<ng-container *ngIf="singlePayroll.contractedHours"> / {{singlePayroll.contractedHours | number:'1.0-2'}}</ng-container>
                </mat-panel-description>
            </mat-expansion-panel-header>

            <mat-progress-spinner mode="indeterminate" *ngIf="!tracksForPayroll[singlePayroll.id]?.length"></mat-progress-spinner>

            <app-time-track-list *ngIf="tracksForPayroll[singlePayroll.id]?.length" [tracks]="tracksForPayroll[singlePayroll.id]"></app-time-track-list>
        </mat-expansion-panel>
    </mat-accordion>
    <ng-template #emptyMessage>
        Bisher sind für Sie keine Abrechnungen erfolgt
    </ng-template>
</div>
