import {Component} from '@angular/core';
import {TimeSheetService} from "../time-sheet-service";
import {map, switchMap, take} from "rxjs/operators";
import {Observable} from "rxjs";
import {TimeSheet} from "../time-sheet";
import {ActivatedRoute, ParamMap} from "@angular/router";
import {ReportingService} from "../../reporting/reporting.service";
import {downloadFile} from "../../utils/file-download";
import {TrackService} from "../../tracks/track.service";

@Component({
    selector: 'app-time-sheet-details-view',
    templateUrl: './time-sheet-details-view.component.html',
    styleUrls: ['./time-sheet-details-view.component.scss']
})
export class TimeSheetDetailsViewComponent {

    timeSheet$: Observable<TimeSheet> = this.route.paramMap
        .pipe(
            map((params: ParamMap) => params.get('id')),
            switchMap(id => this.timeSheetService.timeSheets$
                .pipe(map(sheets => sheets.find(sheet => sheet.id === id)))
            )
        );
    tracks$ = this.timeSheet$
        .pipe(switchMap(sheet => {
            return this.timeTrackService.subscribeToTimeTracksById(sheet.timeTrackIds)
        }));

    notBilledTracks$ = this.timeSheet$
        .pipe(switchMap(sheet => {
            return this.timeTrackService.subscribeToTimeTracksById(sheet.notBilledTimeTrackIds)
        }));

    public readonly notBilledActions = [
        {
            icon: 'attach_money',
            action: (track) => this.timeSheet$
                .pipe(
                    take(1),
                    map(timeSheet => {
                        this.timeSheetService.patchTimeTrackIds(timeSheet.id, timeSheet.timeTrackIds.filter(x => x !== track.id),
                            (timeSheet.notBilledTimeTrackIds ?? []).concat(track.id))
                    })).subscribe()
        },
    ];

    public readonly actions = [
        {
            icon: 'money_off',
            action: (track) => this.timeSheet$
                .pipe(
                    take(1),
                    map(timeSheet => {
                        this.timeSheetService.patchTimeTrackIds(timeSheet.id, timeSheet.timeTrackIds.filter(x => x !== track.id),
                            (timeSheet.notBilledTimeTrackIds ?? []).concat(track.id))
                    })).subscribe()
        }
    ]

    constructor(
        private readonly route: ActivatedRoute,
        private readonly timeSheetService: TimeSheetService,
        private readonly reportingService: ReportingService,
        private readonly timeTrackService: TrackService
    ) {
    }

    public downloadCsv(aggregate: boolean) {
        this.timeSheet$
            .pipe(
                take(1),
                map(async timeSheet => {
                    const [name, blob] = await this.reportingService.getTimeSheet(timeSheet.id, aggregate);
                    downloadFile(name, blob);
                })).subscribe()
    }

    public downLoadPdf(aggregate: boolean) {
        this.timeSheet$
            .pipe(
                take(1),
                map(async timeSheet => {
                    const [name, blob] = await this.reportingService.getTimeSheetAsPdf(timeSheet.id, aggregate);
                    downloadFile(name, blob);
                })).subscribe()
    }

}
