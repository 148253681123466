import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {AngularFireAuth} from "@angular/fire/auth";
import * as moment from "moment";
import {BehaviorSubject, from, Observable, of} from "rxjs";
import {distinctUntilChanged, map, mapTo, shareReplay, switchMap, take, tap} from "rxjs/operators";

export class User {
    id: string;
    name: string;
    email: string;
}

@Injectable({
    providedIn: 'root'
})
export class UserService {
    private requestedIdsSubject = new BehaviorSubject<Set<string>>(new Set<string>());
    private users: User[] = [];
    users$ = this.requestedIdsSubject
        .pipe(
            distinctUntilChanged((x, y) => x.size === y.size && [...x].every(element => y.has(element))),
            switchMap(userIds => {
                const idsToFetch: string[] = [];
                for (let userId of userIds) {
                    if (!this.users.find(x => x.id === userId)) {
                        idsToFetch.push(userId);
                    }
                }

                if (idsToFetch.length === 0) {
                    return of(this.users);
                }

                return this.fetchIds(idsToFetch)
                    .pipe(
                        tap(users => this.users.push(...users)),
                        mapTo(this.users)
                    );
            }),
            shareReplay(1)
        );

    constructor(
        private http: HttpClient,
        private fireAuth: AngularFireAuth
    ) {
    }

    public async resolveUserIds(...userIds: string[]): Promise<User[]> {
        const currentIds = new Set(this.requestedIdsSubject.value);
        userIds.forEach(id => currentIds.add(id));
        this.requestedIdsSubject.next(currentIds);

        return this.users$.pipe(
            map(users => users.filter(user => userIds.includes(user.id))),
            take(1)
        ).toPromise();
    }

    public async getMe(): Promise<any> {
        const user = await this.fireAuth.currentUser;
        const token = await user.getIdToken();

        return this.http.get(`${environment.backendUrl}/users/me`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).toPromise();
    }

    public async getReport(start: moment.Moment, end: moment.Moment): Promise<any> {
        const user = await this.fireAuth.currentUser;
        const token = await user.getIdToken();

        return this.http.get(`${environment.backendUrl}/timetrack/grouped`, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            params: {
                start: start.toISOString(),
                end: end.add(1, 'day').toISOString()
            }
        }).toPromise();
    }

    private fetchIds(userIds: string[]): Observable<User[]> {
        return from(this.fireAuth.currentUser)
            .pipe(
                switchMap(user => from(user.getIdToken())),
                switchMap(token => {
                    return this.http.get<User[]>(`${environment.backendUrl}/users`, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        },
                        params: {
                            'userIds': userIds
                        }
                    })
                })
            );
    }
}
