<form [formGroup]="formGroup">
    <input type="hidden" formControlName="id">
    <input type="hidden" formControlName="userId">
    <mat-form-field style="width: 100%">
        <textarea matInput placeholder="Note" type="text" formControlName="note"></textarea>
    </mat-form-field>
    <br>
    <app-datetime-picker placeholder="Beginn" formControlName="start"></app-datetime-picker>
    <br>
    <app-datetime-picker placeholder="Ende" formControlName="end"></app-datetime-picker>
    <br>
    <app-tag-selection-input formControlName="tagIds" [userIdForNewTags]="formGroup.value.userId"></app-tag-selection-input>
</form>
