import {Injectable} from '@angular/core';
import {AngularFirestore} from "@angular/fire/firestore";
import {AngularFireAuth} from "@angular/fire/auth";
import {filter, map, share, shareReplay, switchMap, take, tap} from "rxjs/operators";

export interface IUserUiSettings {
    selectedTags: string[];
    showStart: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class UserUiSettingsService {
    hasSettings = false;

    userUiSettings$ = this.fireAuth.user
        .pipe(filter(u => !!u),
            switchMap(user => {
                return this.firestore
                    .collection('/userUiSettings')
                    .doc(user.uid).valueChanges();
            }),
            tap(x => this.hasSettings = !!x),
            map(settings => (settings ?? {selectedTags: [], showStart: false}) as IUserUiSettings),
            shareReplay()
        );

    constructor(
        private fireAuth: AngularFireAuth,
        private firestore: AngularFirestore
    ) {
    }

    async setStartTrackPanelSettings(tagIds: string[], showStart: boolean) {
        const user = await this.fireAuth.user
            .pipe(
                filter(x => !!x),
                take(1)
            ).toPromise();

        // ToDo is this really safe?
        if (!this.hasSettings) {
            await this.firestore
                .collection('/userUiSettings')
                .doc(user.uid).set({
                    selectedTags: tagIds,
                    showStart: showStart
                } as Partial<IUserUiSettings>);
        } else {
            await this.firestore
                .collection('/userUiSettings')
                .doc(user.uid).update({
                    selectedTags: tagIds,
                    showStart: showStart
                } as Partial<IUserUiSettings>);
        }
    }
}
