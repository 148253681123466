<h1 mat-dialog-title>Neuen Track erfassen</h1>
<mat-dialog-content>
    <app-track-form #trackForm [track]="track"></app-track-form>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button (click)="saveAndClose(trackForm)">
        Speichern
    </button>
    <button mat-button (click)="closeDialog()">
        Schließen
    </button>
</mat-dialog-actions>
