<table mat-table [dataSource]="dataSource" style="width:100%;" multiTemplateDataRows>
    <ng-container matColumnDef="time">
        <th mat-header-cell *matHeaderCellDef>Zeit</th>
        <td mat-cell *matCellDef="let track">
            {{track.start | date:'dd.MM.yyyy'}} {{track.start | date:'HH:mm:ss'}}
            - {{track.end | date:'HH:mm:ss'}}
        </td>
        <td mat-footer-cell *matFooterCellDef> Total</td>
    </ng-container>
    <ng-container matColumnDef="note">
        <th mat-header-cell *matHeaderCellDef>Notiz</th>
        <td mat-cell *matCellDef="let track">{{track.note}}</td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="duration">
        <th mat-header-cell *matHeaderCellDef>Dauer</th>
        <td mat-cell *matCellDef="let track"> {{ displayTrackRuntime(track) }}  </td>
        <td mat-footer-cell *matFooterCellDef>{{totalDuration}}</td>
    </ng-container>

    <ng-container matColumnDef="tags">
        <th mat-header-cell *matHeaderCellDef>Tags</th>
        <td mat-cell *matCellDef="let track">
            <app-tag-chips [tagIds]="track.tagIds"></app-tag-chips>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="user">
        <th mat-header-cell *matHeaderCellDef>Nutzer</th>
        <td mat-cell *matCellDef="let track">
            {{track.userId | userName | async}}
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Aktionen</th>
        <td mat-cell *matCellDef="let track; let i = dataIndex;">
            <button *ngFor="let action of actions" mat-icon-button (click)="$event.stopPropagation(); action.action(this.track);">
                <mat-icon>{{action.icon}}</mat-icon>
            </button>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns;"></tr>

    <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>
</table>
<mat-paginator [pageSize]="10"
               [pageSizeOptions]="[5, 10, 25, 100]"
               showFirstLastButtons>
</mat-paginator>
