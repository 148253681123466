import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {TagService} from "../tag.service";
import {Observable, ReplaySubject} from "rxjs";
import {Tag} from "../tag";
import {map, switchMap} from "rxjs/operators";

@Component({
    selector: 'app-tag-chips',
    templateUrl: './tag-chips.component.html',
    styleUrls: ['./tag-chips.component.scss']
})
export class TagChipsComponent implements OnChanges {
    @Input() tagIds: string[] = [];

    tagIdSubject = new ReplaySubject<string[]>(1);
    tags$: Observable<Tag[]> = this.tagIdSubject
        .pipe(
            switchMap(ids => {
                return this.tagService.getTagsObservable()
                    .pipe(map(tags => tags.filter(tag => ids.includes(tag.id))))
            })
        );

    constructor(private tagService: TagService) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!this.tagIds || this.tagIds.length === 0) {
            return;
        }
        this.tagIdSubject.next(this.tagIds);
    }

}
