import {Pipe, PipeTransform} from '@angular/core';
import {UserService} from "./user.service";

@Pipe({
    name: 'userName'
})
export class UserNamePipe implements PipeTransform {

    constructor(private readonly userService: UserService) {
    }

    async transform(userId: string): Promise<string> {
        const user = await this.userService.resolveUserIds(userId)
        return user[0]?.name ?? 'user not found';
    }
}
