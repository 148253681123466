import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatListModule} from '@angular/material/list';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatTableModule} from '@angular/material/table';
import {MatCardModule} from '@angular/material/card';
import {MatInputModule} from '@angular/material/input';
import {TrackFormComponent} from './tracks/track-form/track-form.component';
import {AngularFireModule} from '@angular/fire';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {HttpClientModule} from '@angular/common/http';
import {DatetimePickerComponent} from './shared/datetime-picker/datetime-picker.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {AngularFirestoreModule, SETTINGS} from '@angular/fire/firestore';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatChipsModule} from '@angular/material/chips';
import {ReportingComponent} from './reporting/reporting.component';
import {TrackListComponent} from './tracks/track-list/track-list.component';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {MAT_DATE_LOCALE} from '@angular/material/core';
import {PayrollComponent} from './payroll/payroll.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {TrackAddDialogComponent} from './tracks/track-add-dialog/track-add-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {TagNamePipe} from './tags/tag-name.pipe';
import {MatSelectModule} from '@angular/material/select';
import {PayrollAdminComponent} from './payroll/payroll-admin/payroll-admin.component';
import {TagAdminComponent} from './tags/tag-admin/tag-admin.component';
import {TimeSheetDetailsViewComponent} from './time-sheets/time-sheet-details-view/time-sheet-details-view.component';
import {TimeTrackListComponent} from './shared/time-track-list/time-track-list.component';
import {UserNamePipe} from './users/user-name.pipe';
import {TagChipsComponent} from './tags/tag-chips/tag-chips.component';
import {TimeSheetListComponent} from './time-sheets/time-sheet-list/time-sheet-list.component';
import {TagSelectionInputComponent} from './shared/tag-selection-input/tag-selection-input.component';
import {TimeSheetCreationComponent} from './time-sheets/time-sheet-creation/time-sheet-creation.component';
import {MatCheckboxModule} from "@angular/material/checkbox";
import { TrackBarChartComponent } from './tracks/track-bar-chart/track-bar-chart.component';

@NgModule({
    declarations: [
        AppComponent,
        TrackFormComponent,
        DatetimePickerComponent,
        ReportingComponent,
        TrackListComponent,
        PayrollComponent,
        TrackAddDialogComponent,
        TagNamePipe,
        PayrollAdminComponent,
        TagAdminComponent,
        TimeSheetDetailsViewComponent,
        TimeTrackListComponent,
        UserNamePipe,
        TagChipsComponent,
        TimeSheetListComponent,
        TagSelectionInputComponent,
        TimeSheetCreationComponent,
        TrackBarChartComponent
    ],
    imports: [
        BrowserModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireAuthModule,
        AngularFirestoreModule.enablePersistence(),
        AppRoutingModule,
        BrowserAnimationsModule,
        MatSidenavModule,
        MatToolbarModule,
        MatIconModule,
        MatButtonModule,
        MatDatepickerModule,
        MatMomentDateModule,
        MatSnackBarModule,
        HttpClientModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            registrationStrategy: 'registerImmediately'
        }),
        MatListModule,
        FormsModule,
        MatTableModule,
        MatCardModule,
        MatInputModule,
        ReactiveFormsModule,
        MatProgressSpinnerModule,
        MatAutocompleteModule,
        MatPaginatorModule,
        MatChipsModule,
        NgxChartsModule,
        MatExpansionModule,
        MatDialogModule,
        MatSelectModule,
        MatCheckboxModule,
    ],
    providers: [
        {
            provide: SETTINGS,
            useValue: (environment.production || environment.useProdFirebase) ? undefined : {
                host: 'localhost:8000',
                ssl: false
            }
        },
        {provide: MAT_DATE_LOCALE, useValue: 'de-DE'}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
