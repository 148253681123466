import {Injectable} from '@angular/core';
import {parseRawTrack, Track, TrackRaw} from './track';
import {combineLatest, forkJoin, Observable, ReplaySubject} from 'rxjs';
import {filter, first, map, switchMap, take} from 'rxjs/operators';

import {Guid} from '../utils/guid';
import {AngularFireAuth} from "@angular/fire/auth";
import {AngularFirestore} from "@angular/fire/firestore";
import {MatSnackBar} from "@angular/material/snack-bar";
import * as moment from "moment";
import {Moment} from "moment";
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {BaseService} from "../utils/base-service";


@Injectable({
    providedIn: 'root'
})
export class TrackService extends BaseService {
    trackSubject = new ReplaySubject<Track[]>(1);

    getTrackObservable(): Observable<Track[]> {
        return this.fireAuth.user.pipe(
            filter(user => !!user),
            switchMap((user) => {
                return this.firestore.collection(
                    '/tracks',
                    ref => {
                        let query = ref
                            .where('userId', '==', user.uid)
                            .where('deleted', '==', false)
                            .where('start', '>', moment().subtract(2, "month").toDate());

                        return query.orderBy('start', 'desc')
                    }
                ).valueChanges({idField: 'id'});
            }),
            map(tracks => tracks.map(parseRawTrack)),
        );
    }

    currentTrackObservable(): Observable<Track> {
        return this.trackSubject
            .asObservable()
            .pipe(
                map(tracks => tracks.filter(t => !t.end)),
                map(tracks => tracks.length ? tracks[0] : null)
            );
    }

    constructor(
        fireAuth: AngularFireAuth,
        private firestore: AngularFirestore,
        private snackBar: MatSnackBar,
        private http: HttpClient
    ) {
       super(fireAuth);

        combineLatest([
            this.fireAuth.user
        ]).pipe(
            filter(user => !!user),
            switchMap(([user]) => {
                return this.firestore.collection(
                    '/tracks',
                    ref => ref
                        .where('userId', '==', user.uid)
                        .where('deleted', '==', false)
                        // .where('processed', '==', false)
                        .orderBy('start', 'desc')
                ).valueChanges({idField: 'id'});
            }),
            map(tracks => tracks.map(parseRawTrack)),
        ).subscribe((data: Track[]) => this.trackSubject.next(data));
    }

    startTrack(tagId?: string): Promise<Track> {
        return this.addTrack(new Date(), null, '', tagId);
    }

    public async stopTrack(track: Track = null): Promise<void> {
        if (track === null) {
            track = await this.currentTrackObservable().pipe(take(1)).toPromise();
        }

        if (track !== null) {
            track.end = new Date();
            await this.updateTrack(track.id, track);
        }
    }

    async addTrack(start: Date, end: Date, note: string, tagId?: string): Promise<Track> {
        const track: Track = {
            id: Guid.newGuid(),
            start,
            end,
            note,
            lastModified: new Date(),
            userId: '',
            processed: false,
            deleted: false,
            tagIds: tagId ? [tagId] : []
        };
        const user = await this.fireAuth.user.pipe(
            filter(token => !!token),
            take(1)).toPromise();
        track.userId = user.uid;
        await this.firestore.doc('/tracks/' + track.id).set(track);
        return track;
    }

    async createTrack(track: Track) {
        if (!track.id) {
            track.id = Guid.newGuid();
        }

        track.userId = await this.fireAuth.user
            .pipe(
                first(user => !!user),
                map(user => user.uid)
            ).toPromise();

        await this.firestore.doc('/tracks/' + track.id).set(track);

        return true;
    }

    async deleteTrack(id: string): Promise<void> {
        return this.firestore.doc('/tracks/' + id).update({deleted: true});
    }

    async updateTrack(id: string, track: Track): Promise<boolean> {
        try {
            await this.firestore.firestore.runTransaction(async transaction => {
                const ref = this.firestore.firestore.collection("tracks").doc(id);
                const trackDoc = await transaction.get(ref);
                if (!trackDoc.exists) throw Error("No Firestore Data found");
                // check pending writes or uses local version
                if (trackDoc.metadata.hasPendingWrites) {
                    throw Error("Tried to update while offline")
                }

                let trackServerData = trackDoc.data() as TrackRaw;
                // check lastModified Server entry > || < || ==
                if (!trackServerData.lastModified || (trackServerData.lastModified.toDate().getTime() === track.lastModified?.getTime())) {
                    const updatedTrack = Object.assign({id: id}, track);
                    updatedTrack.lastModified = new Date();
                    transaction.update(ref, updatedTrack);
                } else {
                    // throw error for catch => abort update
                    throw Error("Server has more recent Time Track value.")
                }
            })
            return true;
        } catch (e) {
            console.log('Failed to update: ' + e.message);
            this.snackBar.open(e.message, "Dismiss")
            return false;
        }
    }

    async restoreTrack(id: string): Promise<void> {
        return this.firestore.doc('/tracks/' + id).update({deleted: false});
    }

    public subscribeToTimeTracksById(trackIds: string[]): Observable<Track[]> {
        return forkJoin(trackIds.map(trackId => this.firestore.doc('/tracks/' + trackId).get())).pipe(
            map(docs => docs.map(doc => {
                const data = doc.data() as TrackRaw;
                data.id = doc.id;
                return data;
            })),
            map(tracks => tracks.map((track: TrackRaw) => ({
                    id: track.id,
                    note: track.note,
                    start: track.start?.toDate(),
                    end: track.end?.toDate(),
                    lastModified: track.lastModified?.toDate(),
                    userId: track.userId,
                    deleted: track.deleted ?? false,
                    tagIds: track.tagIds ?? []
                } as Track))
            )
        )
    }

    public async searchTimeTracks(start: Moment, end: Moment, tagIds: string[]): Promise<Track[]> {
        const rawTracks = await this.http.get<any[]>(environment.backendUrl + '/timetrack', {
            params: {
                'start': start.toISOString(),
                'end': end.toISOString(),
                'tags': tagIds
            },
            headers: await this.getDefaultHeaders()
        }).toPromise();

        return rawTracks.map(raw => ({
            id: raw.id,
            start: new Date(raw.start),
            end: new Date(raw.end),
            note: raw.note,
            userId: raw.userId,
            processed: raw.processed,
            deleted: raw.deleted,
            tagIds: raw.tagIds
        } as Track));
    }
}
