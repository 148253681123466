import {Component, OnInit, ViewChild} from '@angular/core';
import {aggregateTrackRuntime, durationForTrack, formatDuration, Track} from '../track';
import * as moment from 'moment';
import {TrackFormComponent} from '../track-form/track-form.component';
import {Observable, timer} from 'rxjs';
import {MatTableDataSource} from '@angular/material/table';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AngularFireAuth} from '@angular/fire/auth';
import {AngularFirestore} from '@angular/fire/firestore';
import {ClockService} from '../../clock-service/clock.service';
import {TrackService} from '../track.service';
import {
    debounceTime,
    distinctUntilChanged,
    filter,
    map,
    startWith,
    switchMap,
    take,
    tap,
    withLatestFrom
} from 'rxjs/operators';
import {MatPaginator} from '@angular/material/paginator';
import firebase from 'firebase/app';
import User = firebase.User;
import {FormControl, FormGroup} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {TrackAddDialogComponent} from '../track-add-dialog/track-add-dialog.component';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Tag} from '../../tags/tag';
import {TagService} from '../../tags/tag.service';
import {PayrollService} from '../../payroll/payroll.service';
import {Payroll} from '../../payroll/payroll';
import {UserUiSettingsService} from "../../user-ui-settings/user-ui-settings.service";

interface SelectableTag {
    id: string;
    displayName: string;
}

const ShowTrackBarChartStorageKey = "ShowTrackBarChart";

@Component({
    selector: 'app-track-list',
    templateUrl: './track-list.component.html',
    styleUrls: ['./track-list.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class TrackListComponent implements OnInit {

    @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;

    expandedElement: Track = null;

    currentTrackForm = new FormGroup({
        note: new FormControl(''),
        tagIds: new FormControl([]),
    });
    currentTrack: Observable<Track>;

    projectTags: Observable<Tag[]>;
    tags: Observable<Tag>;

    startTrackPanelConfigurationFormGroup = new FormGroup({
        showStartBeforeEachTag: new FormControl(true),
        tagsForStartButton: new FormControl([]),
    });
    startTrackPanelConfigurationActive = false;
    showStartForEveryButton = true;

    showTrackBarChart = false;

    started: boolean;
    totalDuration: string;

    workedTimeToday: string;
    workedTimeThisWeek: string;
    workedTimeThisMonth: string;
    workedSinceLastPayroll: string;

    dataSource = new MatTableDataSource<Track>();
    tracks: Track[] = [];

    displayedColumns = [
        'time',
        'tags',
        'note',
        'duration',
        'actions'
    ];

    // Keep track of this client being used for entering the current comment
    recentlyUpdated: boolean = false;

    user: User;

    constructor(
        private fireAuth: AngularFireAuth,
        private firestore: AngularFirestore,
        private clockService: ClockService,
        private trackService: TrackService,
        private tagService: TagService,
        private payrollService: PayrollService,
        private userUiSettingsService: UserUiSettingsService,
        private snackBar: MatSnackBar,
        private dialog: MatDialog
    ) {
    }

    ngOnInit(): void {
        const showTrackBarChartJson = localStorage.getItem(ShowTrackBarChartStorageKey);
        this.showTrackBarChart = showTrackBarChartJson ? JSON.parse(showTrackBarChartJson) : false;

        this.userUiSettingsService.userUiSettings$.subscribe(settings => {
            this.startTrackPanelConfigurationFormGroup.patchValue({
                tagsForStartButton: settings.selectedTags,
                showStartBeforeEachTag: settings.showStart
            }, {emitEvent: false});

            this.showStartForEveryButton = settings.showStart;
        })

        this.projectTags = this.userUiSettingsService.userUiSettings$
            .pipe(
                switchMap(settings => this.tagService.getTagsObservable()
                    .pipe(
                        map(tags => tags.filter(tag => settings.selectedTags.includes(tag.id)))
                    )
                ),
            );
        this.currentTrack = this.trackService.currentTrackObservable();
        this.trackService.getTrackObservable()
            .pipe(
                tap(tracks => {
                    tracks.sort((a, b) => moment(b.start).diff(moment(a.start)));
                }),
                tap(tracks => {
                    this.tracks = [...tracks];
                    const duration = moment.duration(tracks.reduce((a, b) => a + durationForTrack(b), 0));
                    this.totalDuration = formatDuration(duration);
                }),
                tap(tracks => {
                    this.dataSource.data = [...tracks];
                })
            ).subscribe();

        setTimeout(() => this.dataSource.paginator = this.paginator);

        this.currentTrackForm.get('tagIds').valueChanges
            .subscribe(async change => {
                const track = await this.currentTrack.pipe(take(1)).toPromise();
                if (!track) {
                    return;
                }

                track.tagIds = change;
                track.end = track.end ?? null;
                await this.trackService.updateTrack(track.id, track);

                this.recentlyUpdated = true;
                timer(5000).subscribe(_ => this.recentlyUpdated = false);
            })

        this.currentTrackForm.get('note').valueChanges
            .pipe(
                debounceTime(500),
                distinctUntilChanged()
            )
            .subscribe(async change => {
                const track = await this.currentTrack.pipe(take(1)).toPromise();
                if (!track) {
                    return;
                }

                track.note = change;
                track.end = track.end ?? null;
                await this.trackService.updateTrack(track.id, track);

                this.recentlyUpdated = true;
                timer(5000).subscribe(_ => this.recentlyUpdated = false);
            });

        this.currentTrack.subscribe(track => {
            if (this.recentlyUpdated || !track) {
                return;
            }

            this.currentTrackForm.patchValue({
                note: track.note,
                tagIds: track.tagIds
            }, {emitEvent: false});
        });

        this.clockService.getClock()
            .pipe(
                filter((_, i) => i % 10 === 0),
                startWith(),
                withLatestFrom(this.payrollService.getLatestOwnPayroll().pipe(startWith(null as Payroll)))
            )
            .subscribe(([_, payroll]) => {
                this.updateWorkedTimeTile(
                    this.dataSource.data,
                    payroll?.end
                );
            });

        this.startTrackPanelConfigurationFormGroup.valueChanges.pipe(
            debounceTime(500),
            distinctUntilChanged()
        ).subscribe(async settings => {
            console.log(settings);
            await this.userUiSettingsService.setStartTrackPanelSettings(
                settings.tagsForStartButton,
                settings.showStartBeforeEachTag
            );
        })
    }

    public async startTrack(tagId?: string) {
        this.started = true;
        await this.trackService.startTrack(tagId);
        this.started = false;
    }

    public stopTrack(track: Track = null) {
        this.trackService.stopTrack(track);
    }

    public async resumeTrack(track: Track): Promise<void> {
        if (track.end) {
            track.end = null;
        }

        await this.trackService.updateTrack(track.id, track);
    }

    public setShowTrackBarChart(show: boolean) {
        this.showTrackBarChart = show;
        localStorage.setItem(ShowTrackBarChartStorageKey, JSON.stringify(this.showTrackBarChart));
    }

    displayTrackRuntime(track: Track): string {
        const diff = durationForTrack(track);
        return formatDuration(moment.duration(diff));
    }

    selectTrack(track: Track) {
        if (this.expandedElement?.id === track.id) {
            this.expandedElement = null;
        } else {
            this.expandedElement = track;
        }
    }

    async saveAndCloseForm(trackFormComponent: TrackFormComponent) {
        const result = await trackFormComponent.save();
        if (result) {
            this.expandedElement = null;
        }
    }

    async discardAndCloseForm() {
        this.expandedElement = null;
    }

    async triggerDeleteTrack(track: Track) {
        const snack = this.snackBar.open('Zeit gelöscht.', 'Rückgängig machen');

        const deletedTrackId = track.id;

        await this.trackService.deleteTrack(track.id);

        const timeout = timer(8000).subscribe(async _ => {
            snack.dismiss();
        });

        snack.onAction().subscribe(async _ => {
            await this.trackService.restoreTrack(deletedTrackId);
            timeout.unsubscribe();
        });
    }

    openTrackAddDialog(): void {
        this.dialog.open(TrackAddDialogComponent, {});
    }

    private updateWorkedTimeTile(tracks: Track[], lastPayrollEnd?: Date) {
        this.workedTimeToday = formatDuration(
            aggregateTrackRuntime(
                tracks.filter(track => moment(track.start).isSame(moment(), 'day'))
            )
        );
        this.workedTimeThisWeek = formatDuration(
            aggregateTrackRuntime(
                tracks.filter(track => moment(track.start).isSame(moment(), 'isoWeek'))
            )
        );
        this.workedTimeThisMonth = formatDuration(
            aggregateTrackRuntime(
                tracks.filter(track => moment(track.start).isSame(moment(), 'month'))
            )
        );

        if (lastPayrollEnd !== undefined) {
            this.workedSinceLastPayroll = formatDuration(
                aggregateTrackRuntime(
                    tracks.filter(track => moment(track.start).isAfter(lastPayrollEnd))
                )
            );
        }
    }
}
